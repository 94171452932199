var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"m-toggle",class:{
    'm-toggle--is-dark': _vm.darkTheme,
    'm-toggle--is-disabled': _vm.disabled,
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.toggleState),expression:"toggleState"}],staticClass:"m-toggle__input",attrs:{"id":_vm.id,"disabled":_vm.disabled,"name":_vm.name,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.toggleState)?_vm._i(_vm.toggleState,null)>-1:(_vm.toggleState)},on:{"change":function($event){var $$a=_vm.toggleState,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.toggleState=$$a.concat([$$v]))}else{$$i>-1&&(_vm.toggleState=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.toggleState=$$c}}}}),(_vm.titlePosition!=='left')?_c('label',{staticClass:"m-toggle__label",style:({
      'font-size': _vm.fontSize,
      'font-weight': _vm.fontWeight,
    }),attrs:{"id":`${_vm.id}-label`,"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('span',{staticClass:"m-toggle__content",style:(_vm.toggleState && {
      'background-color': _vm.activeColor
    }),attrs:{"aria-checked":_vm.toggleState,"aria-disabled":_vm.disabled,"aria-labelledby":`${_vm.id}-label`,"aria-readonly":_vm.disabled,"role":"checkbox"},on:{"click":_vm.toggle}}),(_vm.titlePosition==='left')?_c('label',{staticClass:"m-toggle__label",style:({
      'font-size': _vm.fontSize,
      'font-weight': _vm.fontWeight,
    }),attrs:{"id":`${_vm.id}-label`,"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }